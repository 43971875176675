import ApiClientRequest from "../ApiClientRequest";
import BaseApiClientException from "./BaseApiClientException";
import { ApiClientResponse } from "..";

class ToManyRequestException extends BaseApiClientException {
    static STATUS_CODE = 429;
    constructor(
        request: ApiClientRequest,
        response: ApiClientResponse<unknown>
    ) {
        super(
            "Too Many Requests",
            ToManyRequestException.STATUS_CODE,
            request,
            response
        );
    }
}

export default ToManyRequestException;
