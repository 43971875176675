import LocationControllerInterface from "./LocationControllerInterface";

class LocationController implements LocationControllerInterface {
    constructor(private readonly location: Location) {}
    goTo(link: string): void {
        this.location.href = link;
    }
    getPathname(): string {
        return this.location.pathname;
    }
    getHref(): string {
        return this.location.href;
    }
    getOrigin(): string {
        return this.location.origin;
    }
    getSearchParams(): URLSearchParams {
        const url = new URL(this.location.href);
        return url.searchParams;
    }
    getHashParams(): URLSearchParams {
        return this.location.hash
            ? new URLSearchParams(this.location.hash.slice(1))
            : new URLSearchParams();
    }
}

export default LocationController;
