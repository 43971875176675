import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { ProductApiServiceInterface } from "./api_services/ProductApiServiceInterface";
import { ProductsApiServiceInterface } from "./api_services/ProductsApiServiceInterface";
import { ProductsLiquidApiServiceInterface } from "./api_services/ProductsLiquidApiServiceInterface";
import { ProductsQuery } from "./queries/ProductsQuery";
import { ProductsWithQuantityRulesQuery } from "./queries/ProductsWithQuantityRulesQuery";
import { ProductsServiceInterface } from "./services/ProductsServiceInterface";
import { ProductsStore } from "./stores/ProductsStore";
import { ProductsWithQuantityRulesStore } from "./stores/ProductsWithQuantityRulesStore";

export const productApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductApiServiceInterface>;

export const productsApiServiceToken = Symbol(
    "productsApiServiceToken"
) as InjectionToken<ProductsApiServiceInterface>;

export const productLiquidApiServiceToken = Symbol(
    "productLiquidApiServiceToken"
) as InjectionToken<ProductsLiquidApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStoreToken"
) as InjectionToken<ProductsStore>;

export const productsWithQuantityRulesStoreToken = Symbol(
    "productsWithQuantityRulesStoreToken"
) as InjectionToken<ProductsWithQuantityRulesStore>;

export const productsServiceToken = Symbol(
    "productsServiceToken"
) as InjectionToken<ProductsServiceInterface>;

export const productsQueryToken = Symbol(
    "productsQueryToken"
) as InjectionToken<ProductsQuery>;

export const productsWithQuantityRulesQueryToken = Symbol(
    "productsWithQuantityRulesQueryToken"
) as InjectionToken<ProductsWithQuantityRulesQuery>;

export const commonAuthApiClientToken = Symbol(
    "commonAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;
