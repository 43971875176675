import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import TokenManagerInterface from "@modules/token_manager/TokenManager/TokenManagerInterface";
import { InjectionToken } from "tsyringe";

import { AuthenticationInfoProvider } from "./AuthenticationInfoProvider/AuthenticationInfoProvider";

export const tokenManagerToken = Symbol(
    "tokenManagerToken"
) as InjectionToken<TokenManagerInterface>;

export const authServiceApiClientToken = Symbol(
    "authServiceApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const authenticationInfoProviderToken = Symbol(
    "AuthenticationInfoProviderToken"
) as InjectionToken<AuthenticationInfoProvider>;
