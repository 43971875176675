import { localizationInfoProviderToken } from "@/tokens";
import { container } from "tsyringe";

export const getJsonFromScript = (selector: string): unknown => {
    let parsedBody = null;
    const element = document.querySelector(selector);
    if (!element) {
        throw new Error(`element by selector (${selector}) not found`);
    }
    try {
        parsedBody = JSON.parse(element.textContent || "") as unknown;
    } catch (e: unknown) {
        throw new Error("json parse error");
    }
    return parsedBody;
};

export const getHumanReadableDate = (
    date: string | Date,
    withLocale = true
): string => {
    const newDate = new Date(date);

    const localizationInfoProvider = container.resolve(
        localizationInfoProviderToken
    );

    const langIsoCode = withLocale
        ? localizationInfoProvider.getLanguageIsoCode()
        : "en-US";

    return new Intl.DateTimeFormat(langIsoCode, {
        year: "numeric",
        month: "short",
        day: "numeric",
    }).format(newDate);
};
