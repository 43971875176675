import { BehaviorSubject, Observable } from "rxjs";

import { ProductIdProviderInterface } from "./ProductIdProviderInterface";

export class ProductIdFromSdkProvider implements ProductIdProviderInterface {
    private _productId = new BehaviorSubject<number | null>(null);

    setProductId(productId: number | null): void {
        this._productId.next(productId);
    }

    selectProductId(): Observable<number | null> {
        return this._productId.asObservable();
    }
}
