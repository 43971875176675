import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";

function checkIsEmbeddedMode() {
    try {
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );
        return !!additionalInfoData && typeof additionalInfoData === "object";
    } catch {
        return false;
    }
}

export default checkIsEmbeddedMode;
