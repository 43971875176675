import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { StorefrontApiAccessTokenProviderInterface } from "./StorefrontApiAccessTokenProviderInterface";

@singleton()
class StorefrontApiAccessTokenProvider
    implements StorefrontApiAccessTokenProviderInterface
{
    private token: string | null = null;

    constructor(private readonly logger: LoggerInterface) {}

    getToken() {
        this.logger.debug("StorefrontApiAccessTokenProvider.getToken");
        return this.token;
    }
}

export default StorefrontApiAccessTokenProvider;
