import AnalyticsEventsCollectorInterface from "@modules/analytics_events_collector/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { singleton } from "tsyringe";

import SdkAnalyticsEventsFactory from "../analytics/SdkAnalyticsEventsFactory";
import { SdkMethodPayloadInterface } from "./SdkMethodPayloadInterface";

type SubscriberCallback = (payload: SdkMethodPayloadInterface) => void;

@singleton()
class SdkMethodsCollector {
    private _subscribers: {
        [methodName: string]: SubscriberCallback[];
    };

    constructor(
        private readonly eventsCollector: AnalyticsEventsCollectorInterface,
        private readonly eventsFactory: SdkAnalyticsEventsFactory
    ) {
        this._subscribers = {};
    }

    addSubscriber<T extends SdkMethodPayloadInterface>(
        methodName: T["methodName"],
        callback: (payload: T) => void
    ): void {
        this.eventsCollector.pushEvent(
            this.eventsFactory.createMethodSubscribed(methodName)
        );

        if (!this._subscribers[methodName]) {
            this._subscribers[methodName] = [];
        }
        this._subscribers[methodName].push(callback as SubscriberCallback);
    }

    deleteSubscriber<T extends SdkMethodPayloadInterface>(
        methodName: T["methodName"],
        callback: (payload: T) => void
    ): void {
        if (this._subscribers[methodName]) {
            this.eventsCollector.pushEvent(
                this.eventsFactory.createMethodUnsubscribed(methodName)
            );

            this._subscribers[methodName] = this._subscribers[
                methodName
            ].filter((cb) => cb !== callback);
        }
    }

    hasSubscribers(methodName: string): boolean {
        return this._subscribers[methodName]?.length > 0;
    }

    notifySubscribers(payload: SdkMethodPayloadInterface): void {
        this.eventsCollector.pushEvent(
            this.eventsFactory.createMethodCalled(payload.methodName)
        );

        this._subscribers[payload.methodName]?.forEach((callback) =>
            callback(payload)
        );
    }
}

export default SdkMethodsCollector;
