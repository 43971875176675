import { QueryEntity } from "@datorama/akita";
import { map } from "rxjs/operators";
import { injectable } from "tsyringe";

import {
    ProductsWithQuantityRulesState,
    ProductsWithQuantityRulesStore,
} from "../stores/ProductsWithQuantityRulesStore";

@injectable()
export class ProductsWithQuantityRulesQuery extends QueryEntity<ProductsWithQuantityRulesState> {
    constructor(protected store: ProductsWithQuantityRulesStore) {
        super(store);
    }

    public selectProduct(productId: number) {
        return this.selectAll().pipe(
            map((products) =>
                products.find((product) => product.id === productId)
            ),
            map((product) => product || null)
        );
    }

    public selectQuantityRule(productId: number, variantId: number) {
        return this.selectProduct(productId).pipe(
            map((product) => product?.variants || []),
            map((variants) =>
                variants.find((variant) => variant.id === variantId)
            ),
            map((variant) => variant?.quantityRule || null)
        );
    }
}
