// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root, :host {
    --gw-alert-standart-error: rgb(253, 237, 237);
    --gw-alert-standart-warning: rgb(255, 244, 229);
    --gw-alert-standart-success: rgb(237, 247, 237);
    --gw-alert-standart-info: rgb(229, 246, 253);

    --gw-alert-filled-error: #d32f2f;
    --gw-alert-filled-warning: #ed6c02;
    --gw-alert-filled-success: #2e7d32;
    --gw-alert-filled-info: #0288d1;

    --gw-alert-outlined-error: #ef5350;
    --gw-alert-outlined-warning: #ff9800;
    --gw-alert-outlined-success: #4caf50;
    --gw-alert-outlined-info: #03a9f4;

    --gw-alert-text-color-error: rgb(95, 33, 32);
    --gw-alert-text-color-warning: rgb(102, 60, 0);
    --gw-alert-text-color-info: rgb(1, 67, 97);
    --gw-alert-text-color-success: rgb(30, 70, 32);
    --gw-alert-text-color-white: #fff;
}`, "",{"version":3,"sources":["webpack://./src/themes/default/alert.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,+CAA+C;IAC/C,+CAA+C;IAC/C,4CAA4C;;IAE5C,gCAAgC;IAChC,kCAAkC;IAClC,kCAAkC;IAClC,+BAA+B;;IAE/B,kCAAkC;IAClC,oCAAoC;IACpC,oCAAoC;IACpC,iCAAiC;;IAEjC,4CAA4C;IAC5C,8CAA8C;IAC9C,0CAA0C;IAC1C,8CAA8C;IAC9C,iCAAiC;AACrC","sourcesContent":[":root, :host {\n    --gw-alert-standart-error: rgb(253, 237, 237);\n    --gw-alert-standart-warning: rgb(255, 244, 229);\n    --gw-alert-standart-success: rgb(237, 247, 237);\n    --gw-alert-standart-info: rgb(229, 246, 253);\n\n    --gw-alert-filled-error: #d32f2f;\n    --gw-alert-filled-warning: #ed6c02;\n    --gw-alert-filled-success: #2e7d32;\n    --gw-alert-filled-info: #0288d1;\n\n    --gw-alert-outlined-error: #ef5350;\n    --gw-alert-outlined-warning: #ff9800;\n    --gw-alert-outlined-success: #4caf50;\n    --gw-alert-outlined-info: #03a9f4;\n\n    --gw-alert-text-color-error: rgb(95, 33, 32);\n    --gw-alert-text-color-warning: rgb(102, 60, 0);\n    --gw-alert-text-color-info: rgb(1, 67, 97);\n    --gw-alert-text-color-success: rgb(30, 70, 32);\n    --gw-alert-text-color-white: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
