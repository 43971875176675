import LocationControllerInterface from "@modules/common_module/LocationController/LocationControllerInterface";

class HistoryPatcher {
    constructor(
        private readonly seach_params: string[],
        private readonly history: History,
        private readonly locationController: LocationControllerInterface
    ) {}
    patchFunction(
        origFunc: (
            ...args: Parameters<History["pushState"] | History["replaceState"]>
        ) => void
    ): (...args: Parameters<History["pushState"]>) => void {
        return (...args: Parameters<typeof origFunc>) => {
            const searchParams = this.locationController.getSearchParams();
            const [state, unused, url] = args;
            let newUrl = url;

            if (url) {
                const _url = new URL(url, this.locationController.getOrigin());

                for (const [key, value] of searchParams.entries()) {
                    if (this.seach_params.includes(key)) {
                        _url.searchParams.append(key, value);
                    }
                }

                const withoutHash = url.toString().split("#")[0];

                newUrl = withoutHash.split("?")[0];

                if (_url.search) {
                    newUrl += _url.search;
                }

                if (_url.hash) {
                    newUrl += _url.hash;
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            origFunc(state, unused, newUrl);
            args[0];
        };
    }
    patch() {
        const originalPushState = this.history.pushState.bind(this.history);
        const originalReplaceState = this.history.replaceState.bind(
            this.history
        );
        this.history.pushState = this.patchFunction(originalPushState);
        this.history.replaceState = this.patchFunction(originalReplaceState);
    }
}

export default HistoryPatcher;
