import {
    array,
    boolean,
    nullable,
    number,
    object,
    record,
    string,
} from "superstruct";

const ImageSchema = object({
    url: string(),
    id: string(),
    altText: nullable(string()),
});

export const ShopifyGraphQLProductSchema = object({
    id: string(),
    title: string(),
    description: string(),
    handle: string(),
    featuredImage: nullable(ImageSchema),
    images: object({
        nodes: array(ImageSchema),
    }),
    options: array(
        object({
            id: string(),
            name: string(),
            values: array(string()),
        })
    ),
    variants: object({
        nodes: array(
            object({
                title: string(),
                id: string(),
                image: nullable(ImageSchema),
                price: object({
                    amount: string(),
                    currencyCode: string(),
                }),
                compareAtPrice: nullable(
                    object({
                        amount: string(),
                        currencyCode: string(),
                    })
                ),
                selectedOptions: array(
                    object({
                        name: string(),
                        value: string(),
                    })
                ),
                currentlyNotInStock: boolean(),
                quantityAvailable: number(),
                availableForSale: boolean(),
            })
        ),
    }),
});
