import {
    GROWAVE_TOKEN_STRATEGY,
    TOKEN_STRATEGY_HEADER_KEY,
    TOKEN_STRATEGY_QUERY_PARAMETER,
} from "@/constants/current_app";
import TokenManagerInterface from "@modules/token_manager/TokenManager/TokenManagerInterface";
import { Observable, from, of, race, timer } from "rxjs";
import { filter, map, mapTo, switchMap, take, tap } from "rxjs/operators";

import ApiClientInterface from "./ApiClientInterface";
import ApiClientRequest from "./ApiClientRequest";
import { ApiClientResponse } from ".";

const tokenManagerInitializedTimeout = timer(30000);

class AuthApiClient implements ApiClientInterface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly tokenManager: TokenManagerInterface
    ) {}
    selectToken(): Observable<string> {
        return race(
            this.tokenManager.isInitialized.pipe(filter((v) => v)),
            tokenManagerInitializedTimeout.pipe(
                mapTo(new Error("Token manager initialization timeout"))
            )
        ).pipe(
            tap((initialized) => {
                if (initialized instanceof Error) {
                    throw initialized;
                }
            }),
            take(1),
            switchMap(() =>
                this.tokenManager.isValidToken()
                    ? of(this.tokenManager.getToken())
                    : from(this.tokenManager.refreshToken()).pipe(
                          map(() => {
                              return this.tokenManager.getToken();
                          })
                      )
            ),
            map((token) => {
                if (!token) {
                    throw new Error(
                        "Cannot get token from token manager in AuthApiClient"
                    );
                }
                return token;
            })
        );
    }
    get(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.get({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                        [TOKEN_STRATEGY_QUERY_PARAMETER]: GROWAVE_TOKEN_STRATEGY
                    },
                    headers: {
                        [TOKEN_STRATEGY_HEADER_KEY]: GROWAVE_TOKEN_STRATEGY,
                        ...reqConfig.headers,
                    },
                });
            })
        );
    }
    delete(
        reqConfig: Omit<ApiClientRequest, "method" | "body">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.delete({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                        [TOKEN_STRATEGY_QUERY_PARAMETER]: GROWAVE_TOKEN_STRATEGY
                    },
                    headers: {
                        [TOKEN_STRATEGY_HEADER_KEY]: GROWAVE_TOKEN_STRATEGY,
                        ...reqConfig.headers,
                    },
                });
            })
        );
    }
    post(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.post({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                        [TOKEN_STRATEGY_QUERY_PARAMETER]: GROWAVE_TOKEN_STRATEGY
                    },
                    headers: {
                        [TOKEN_STRATEGY_HEADER_KEY]: GROWAVE_TOKEN_STRATEGY,
                        ...reqConfig.headers,
                    },
                });
            })
        );
    }
    put(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.put({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                        [TOKEN_STRATEGY_QUERY_PARAMETER]: GROWAVE_TOKEN_STRATEGY
                    },
                    headers: {
                        [TOKEN_STRATEGY_HEADER_KEY]: GROWAVE_TOKEN_STRATEGY,
                        ...reqConfig.headers,
                    },
                });
            })
        );
    }
    patch(
        reqConfig: Omit<ApiClientRequest, "method">
    ): Observable<ApiClientResponse<unknown>> {
        return this.selectToken().pipe(
            switchMap((token) => {
                return this.apiClient.patch({
                    ...reqConfig,
                    queryParams: {
                        ...reqConfig.queryParams,
                        token,
                        [TOKEN_STRATEGY_QUERY_PARAMETER]: GROWAVE_TOKEN_STRATEGY
                    },
                    headers: {
                        [TOKEN_STRATEGY_HEADER_KEY]: GROWAVE_TOKEN_STRATEGY,
                        ...reqConfig.headers,
                    },
                });
            })
        );
    }
}

export default AuthApiClient;
