import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import AnalyticsEventsCollectorInterface from "./AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";

export const eventsCollectorAuthApiClientToken = Symbol(
    "eventsCollectorAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const eventsCollectorToken = Symbol(
    "eventsCollectorToken"
) as InjectionToken<AnalyticsEventsCollectorInterface>;
