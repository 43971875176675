import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

export const loginApplicationLoggerToken = Symbol(
    "loginApplicationLoggerToken"
) as InjectionToken<LoggerInterface>;

export const socialLoginAuthApiClientToken = Symbol(
    "socialLoginAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const socialLoginApiClientToken = Symbol(
    "socialLoginApiClientToken"
) as InjectionToken<ApiClientInterface>;
