export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";
export const PATCH = "PATCH";
export const PUT = "PUT";

export const JsonMimeType = "application/json";
export const FormMimeType = "application/x-www-form-urlencoded";
export const FormDataMimeType = "multipart/form-data";

export const BAD_STATUS = 400;
