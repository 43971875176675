import { growaveConfigurationProviderToken } from "@/tokens";
import { GrowaveConfigurationProviderInterface } from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationProviderInterface";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { from, lastValueFrom, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { inject, singleton } from "tsyringe";

import { AuthApiService } from "../api_services/AuthApiService/AuthApiService";

@singleton()
export class SignedAuthenticator {
    public constructor(
        private readonly authApiService: AuthApiService,
        private readonly tokenManagerModule: TokenManagerModule,
        @inject(growaveConfigurationProviderToken)
        private readonly growaveConfigurationProvider: GrowaveConfigurationProviderInterface
    ) {}

    async authenticate(
        shopifyCustomerId: number,
        signature: string,
        signatureTimestamp: number
    ) {
        const tokenManager = this.tokenManagerModule.exports.tokenManager;
        await lastValueFrom(
            (tokenManager.hasToken() && tokenManager.tokenIsExpired()
                ? from(tokenManager.refreshToken())
                : of(true)
            ).pipe(
                switchMap((isTokenReadyOrNotExists) => {
                    if (!isTokenReadyOrNotExists) {
                        tokenManager.discardToken();
                        throw new Error("Something went wrong");
                    }
                    return this.authApiService
                        .authWithSign({
                            token: this.tokenManagerModule.exports.tokenManager.getToken(),
                            shopifyCustomerId,
                            signature,
                            signatureTimestamp,
                            myshopifyDomain:
                                this.growaveConfigurationProvider.getConfiguration()
                                    .myshopifyDomain,
                        })
                        .pipe(
                            tap((response) => {
                                this.tokenManagerModule.exports.tokenManager.setToken(
                                    response.token
                                );
                                this.tokenManagerModule.exports.tokenManager.setIsInitialized();
                            })
                        );
                })
            )
        );
    }
}
