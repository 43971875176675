import { GP_GW_REFFERER } from "@/constants/get_params";

import ReferrerProviderInterface from "./ReferrerProviderInterface";

class ReferrerProvider implements ReferrerProviderInterface {
    getReferrer() {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(GP_GW_REFFERER);
    }
}

export default ReferrerProvider;
