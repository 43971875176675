export class PathPrefixProvider {
    constructor(private readonly shopifyPrefix: string) {}

    getUrlWithoutLocale(link: string) {
        if (this.shopifyPrefix !== "/") {
            return link.replace(this.shopifyPrefix, "/");
        }
        return link;
    }
}
