import {
    EntityState,
    EntityStore,
    StoreConfig,
    arrayAdd,
    arrayUpdate,
} from "@datorama/akita";
import { singleton } from "tsyringe";

import { ProductStatuses } from "../constants/ProductStatuses";
import ProductWithQuantityRulesEntity from "../entities/ProductWithQuantityRulesEntity";

type StatusItemKey = {
    productId: number;
    status: ProductStatuses;
};

export interface ProductsWithQuantityRulesState
    extends EntityState<ProductWithQuantityRulesEntity, number> {
    productStatuses: StatusItemKey[];
}

export function createInitialState(): ProductsWithQuantityRulesState {
    return {
        productStatuses: [],
    };
}

@singleton()
@StoreConfig({ name: "ProductsWithQuantityRulesStore" })
export class ProductsWithQuantityRulesStore extends EntityStore<ProductsWithQuantityRulesState> {
    constructor() {
        super(createInitialState());
    }

    public addProductInQueue(productId: number) {
        this.update(({ productStatuses }) => {
            if (
                !productStatuses.find(
                    (statusItem) => statusItem.productId === productId
                )
            ) {
                return {
                    productStatuses: arrayAdd(productStatuses, {
                        productId,
                        status: ProductStatuses.IN_QUEUE,
                    }),
                };
            }
        });
    }

    public setLoadingStatus(productId: number) {
        this.update(({ productStatuses }) => ({
            productStatuses: arrayUpdate(
                productStatuses,
                ({ productId: _productId }) => _productId === productId,
                { status: ProductStatuses.LOADING } as StatusItemKey
            ),
        }));
    }

    public setReadyStatus(productId: number) {
        this.update(({ productStatuses }) => ({
            productStatuses: arrayUpdate(
                productStatuses,
                ({ productId: _productId }) => _productId === productId,
                { status: ProductStatuses.READY } as StatusItemKey
            ),
        }));
    }
}
