import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";
import { Observable } from "rxjs";

import { ProductIdProviderInterface } from "./ProductIdProviderInterface";

export class ProductIdFromAdditionalInfoProvider
    implements ProductIdProviderInterface
{
    public selectProductId() {
        return new Observable<number | null>((subscriber) => {
            const data = getJsonFromScript(GW_ADDITIONAL_INFO_SELECTOR);

            subscriber.next(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                ((data as any)["GW_PRODUCT_INFO"]?.["productId"] as
                    | number
                    | null) || null
            );
        });
    }
}
