import { LoggerInterface } from "@interfaces/LoggerInterface";

import GrowaveConfigurationEntity from "./GrowaveConfigurationEntity";
import { GrowaveConfigurationProviderInterface } from "./GrowaveConfigurationProviderInterface";

class GrowaveConfigurationFromStaticProvider
    implements GrowaveConfigurationProviderInterface
{
    constructor(
        private readonly logger: LoggerInterface,
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {}

    getConfiguration(): GrowaveConfigurationEntity {
        this.logger.debug(
            "GrowaveConfigurationFromStaticProvider.getConfiguration",
            {
                growaveConfiguration: this.growaveConfiguration,
            }
        );

        return this.growaveConfiguration;
    }
}

export default GrowaveConfigurationFromStaticProvider;
