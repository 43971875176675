// Current app
export const CURRENT_APP_QUERY_PARAMETER = "x-gw-current-app";
export const CURRENT_APP_HEADER_KEY = "X-Gw-Current-App";
export const CURRENT_APP_GROWAVE = "default";
export const CURRENT_APP_INSTAGRAM = "instagram";

// Token strategy
export const TOKEN_STRATEGY_HEADER_KEY = "X-Gw-Token-Strategy";
export const TOKEN_STRATEGY_QUERY_PARAMETER = "x-gw-token-strategy";
export const GROWAVE_TOKEN_STRATEGY = "growave";
