import { ROLE } from "@modules/authentication/constants";
import GrowaveJwtTokenSchema from "@modules/authentication/schemas/GrowaveJwtTokenSchema";
import { getUnixTime, isBefore, sub } from "date-fns";
import jwtDecode from "jwt-decode";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, finalize, map, share, tap } from "rxjs/operators";
import { Infer, create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import RefreshTokenServiceInterface from "./RefreshTokenServiceInterface";
import TokenManagerInterface from "./TokenManagerInterface";

class GrowaveTokenManager implements TokenManagerInterface {
    public isInitialized = new BehaviorSubject<boolean>(false);
    /**
     * @deprecated
     * Надо избавиться от этого Obserable и переделать сам GrowaveTokenManager на работу с RxJS
     */
    public updates = new BehaviorSubject<null>(null);
    private currentRefreshTokenObservable: Observable<boolean> | null = null;

    constructor(
        private readonly refreshTokenService: RefreshTokenServiceInterface,
        private readonly storage: Storage,
        private readonly tokenStorageKey: string,
        private readonly browserTimeDiffMs: number,
        private readonly myshopifyDomain: string,
        private readonly logger: LoggerInterface
    ) {}

    getToken(): string | null {
        if (this.hasToken()) {
            return this.storage.getItem(this.tokenStorageKey);
        }
        return null;
    }

    setToken(token: string): void {
        this.storage.setItem(this.tokenStorageKey, token);
        this.logger.debug("GrowaveTokenManager.setToken", {
            token,
        });
        this.updates.next(null);
    }

    setIsInitialized(): void {
        this.isInitialized.next(true);
        this.logger.debug("GrowaveTokenManager.setIsInitialized");
    }

    getTokenPayload(): Infer<typeof GrowaveJwtTokenSchema> | null {
        const token = this.getToken();
        if (!token) {
            return null;
        }
        const payload = create(jwtDecode(token), GrowaveJwtTokenSchema);
        return payload;
    }

    isGuest(): boolean {
        return this.getTokenPayload()?.data.role === ROLE.Guest;
    }

    isCustomer(): boolean {
        return this.getTokenPayload()?.data.role === ROLE.Customer;
    }

    hasToken(): boolean {
        const token = this.storage.getItem(this.tokenStorageKey);
        if (!token) return false;
        return true;
    }

    tokenIsExpired(): boolean {
        const payload = this.getTokenPayload();
        if (payload) {
            return isBefore(
                payload.exp,
                getUnixTime(
                    sub(new Date(), {
                        seconds: this.browserTimeDiffMs / 1000,
                    })
                )
            );
        }

        return false;
    }

    isValidToken(): boolean {
        return this.hasToken() && !this.tokenIsExpired();
    }

    discardToken(): void {
        this.storage.removeItem(this.tokenStorageKey);
        this.logger.debug("GrowaveTokenManager.discardToken");
        this.updates.next(null);
    }

    /**
     * Этот метод обновит токен, если токен/рефреш-токен уже имеется
     * В противном случае вернется False
     */
    refreshToken(): Observable<boolean> {
        if (this.currentRefreshTokenObservable) {
            return this.currentRefreshTokenObservable;
        }
        const token = this.getToken();
        if (!token) {
            this.logger.error("refreshToken without token");
            return of(false);
        }
        const payload = this.getTokenPayload();
        if (!payload) {
            this.logger.error("refreshToken without token");
            return of(false);
        }
        const currentRefreshTokenObservable = this.refreshTokenService
            .refreshToken({
                currentToken: token,
                myshopifyDomain: this.myshopifyDomain,
            })
            .pipe(
                tap((tokens) => {
                    this.currentRefreshTokenObservable = null;
                    this.setToken(tokens.token);
                }),
                map(() => true),
                catchError((error: unknown) => {
                    return of(false).pipe(
                        tap(() => {
                            this.logger.warn("Error on update token", {
                                error,
                            });
                            this.discardToken();
                        })
                    );
                }),
                finalize(() => {
                    this.currentRefreshTokenObservable = null;
                }),
                share()
            );
        this.currentRefreshTokenObservable = currentRefreshTokenObservable;
        return currentRefreshTokenObservable;
    }
}

export default GrowaveTokenManager;
