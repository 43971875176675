import { Observable, distinctUntilChanged, interval, map } from "rxjs";

import { ProductVariantIdProviderInterface } from "./ProductVariantIdProviderInterface";

export class ProductVariantIdFromUrlProvider
    implements ProductVariantIdProviderInterface
{
    private searchParamsVariantIdKey = "variant";

    private _getVariantIdFromUrl() {
        const url = new URL(window.location.href);
        const variantId = url.searchParams.get(this.searchParamsVariantIdKey);
        return Number(variantId) || null;
    }

    variantId$ = interval(100).pipe(
        map(() => {
            return this._getVariantIdFromUrl();
        }),
        distinctUntilChanged()
    );

    selectVariantId(): Observable<number | null> {
        return this.variantId$.pipe(map((val) => Number(val) || null));
    }
}
