import {
    array,
    integer,
    literal,
    nullable,
    number,
    object,
    string,
    type,
    union,
} from "superstruct";

export const GrowaveProductResponseSchema = object({
    product: type({
        id: number(),
        title: string(),
        description: string(),
        slug: string(),
        comparePrice: nullable(integer()),
        createdAt: string(),
        updatedAt: string(),
        publishedAt: string(),
        imageUrl: string(),
        images: array(
            object({
                id: number(),
                url: string(),
            })
        ),
        options: array(
            object({
                name: string(),
                values: array(string()),
            })
        ),
        price: number(),
        variants: array(
            type({
                title: string(),
                comparePrice: nullable(integer()),
                price: integer(),
                id: integer(),
                imageId: nullable(integer()),
                inventoryManagement: nullable(string()),
                inventoryPolicy: union([literal("deny"), literal("continue")]),
                inventoryQuantity: integer(),
                option1: nullable(string()),
                option2: nullable(string()),
                option3: nullable(string()),
            })
        ),
    }),
});
