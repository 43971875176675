import ApiClientRequest from "../ApiClientRequest";
import BaseApiClientException from "./BaseApiClientException";
import { ApiClientResponse } from "..";

class UnauthorizedException extends BaseApiClientException {
    static STATUS_CODE = 401;
    constructor(
        request: ApiClientRequest,
        response: ApiClientResponse<unknown>
    ) {
        super(
            "Unauthorized",
            UnauthorizedException.STATUS_CODE,
            request,
            response
        );
    }
}

export default UnauthorizedException;
