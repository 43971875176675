import { METHOD_STATUSES } from "@modules/sdk/constants";
import { SdkMethodsInterface } from "@modules/sdk/methods/SdkMethodsInterface";
import { SuccessResult } from "@modules/sdk/types";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { inject, singleton } from "tsyringe";

import { AuthenticationInfoProviderInterface } from "../AuthenticationInfoProvider/AuthenticationInfoProviderInterface";
import { SignedAuthenticator } from "../authenticators/SignedAuthenticator";
import { authenticationInfoProviderToken } from "../di_tokens";
import { AUTHENTICATION_SDK_METHODS } from "./constants";

@singleton()
class AuthenticationSdkMethods
    implements SdkMethodsInterface<AUTHENTICATION_SDK_METHODS>
{
    constructor(
        private readonly tokenManagerModule: TokenManagerModule,
        private readonly signedAuthenticator: SignedAuthenticator,
        @inject(authenticationInfoProviderToken)
        private readonly authenticationInfoProviderToken: AuthenticationInfoProviderInterface
    ) {}

    public async authenticateCustomer({
        shopifyCustomerId,
        signature,
        signatureTimestamp,
    }: {
        shopifyCustomerId: number;
        signature: string;
        signatureTimestamp: number;
    }): Promise<SuccessResult> {
        const result = await this.signedAuthenticator.authenticate(
            shopifyCustomerId,
            signature,
            signatureTimestamp
        );
        return {
            status: METHOD_STATUSES.SUCCESS,
        };
    }
    public async logoutCustomer(): Promise<SuccessResult> {
        this.tokenManagerModule.exports.tokenManager.discardToken();
        return Promise.resolve({
            status: METHOD_STATUSES.SUCCESS,
        });
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public getCurrentRole(): string | null {
        return this.authenticationInfoProviderToken.getCurrentRole();
    }
}

export default AuthenticationSdkMethods;
