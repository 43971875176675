export class ShopifyUrlUtils {
    private LINK_REGEX = /^https?:\/\//gm;

    constructor(public readonly urlPrefix: string) {}

    /**
     * Added a language preffix to url
     */
    withPrefix(link: string): string {
        if (this.LINK_REGEX.test(link))
            throw new Error("Link should be relative");
        link = link.replace(/^\/+/g, "");
        return this.urlPrefix + link;
    }
}
