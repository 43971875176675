import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import GrowaveTokenManager from "./TokenManager/GrowaveTokenManager";

export const tokenManagerToken = Symbol(
    "tokenManagerToken"
) as InjectionToken<GrowaveTokenManager>;

export const refreshTokenServiceApiClientToken = Symbol(
    "refreshTokenServiceApiClient"
) as InjectionToken<ApiClientInterface>;
