import ApiClientRequest from "../ApiClientRequest";
import BaseApiClientException from "./BaseApiClientException";
import { ApiClientResponse } from "..";

class ForbiddenException extends BaseApiClientException {
    static STATUS_CODE = 403;
    constructor(
        request: ApiClientRequest,
        response: ApiClientResponse<unknown>
    ) {
        super("Forbidden", ForbiddenException.STATUS_CODE, request, response);
    }
}

export default ForbiddenException;
