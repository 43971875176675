import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";

export class StorefrontApiBaseUrlProvider {
    constructor(
        private readonly gwStorefrontAppInfo: GwStorefrontAppInfo,
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {}
    getBaseUrl() {
        if (this.growaveConfiguration.initedFromSdk) {
            return `https://${this.growaveConfiguration.myshopifyDomain}`;
        } else {
            return "";
        }
    }
    appendToBaseUrl(path: string) {
        return this.getBaseUrl() + path;
    }
}
