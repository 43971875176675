import { LocalizationInfoProviderInterface } from "./LocalizationInfoProviderInterface";

export class SimpleLocalizationInfoProvider
    implements LocalizationInfoProviderInterface
{
    public constructor(
        private readonly countryIsoCode: string,
        private readonly languageIsoCode: string,
        private readonly currencyIsoCode: string,
        private readonly gwI18nLocale: string
    ) {}

    getCountryIsoCode() {
        return this.countryIsoCode;
    }
    getLanguageIsoCode() {
        return this.languageIsoCode;
    }
    getCurrencyIsoCode() {
        return this.currencyIsoCode;
    }
    getGwI18nLocale() {
        return this.gwI18nLocale;
    }
}
