import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { CommonModule } from "@modules/common_module/CommonModule";
import { container } from "tsyringe";

import { METHOD_STATUSES } from "../constants";
import { SdkMethodsInterface } from "../methods/SdkMethodsInterface";
import { SuccessResult } from "../types";
import { COMMON_SDK_METHODS } from "./constants";

class CommonSdkMethods implements SdkMethodsInterface<COMMON_SDK_METHODS> {
    constructor(
        private readonly _productIdProvider: ProductIdFromSdkProvider,
        private readonly _variantIdProvider: ProductVariantIdFromSdkProvider
    ) {}

    public setGlobalProductId(productId: number) {
        return new Promise<SuccessResult>((resolve) => {
            this._productIdProvider.setProductId(productId);
            resolve({ status: METHOD_STATUSES.SUCCESS });
        });
    }

    public setGlobalVariantId(variantId: number) {
        return new Promise<SuccessResult>((resolve) => {
            this._variantIdProvider.setVariantId(variantId);
            resolve({ status: METHOD_STATUSES.SUCCESS });
        });
    }

    public setModifyURLFunction(modifyURL: (url: string) => string) {
        return new Promise<SuccessResult>((resolve) => {
            container
                .resolve(CommonModule)
                .exports.urlsBuilder.setModifyURL(modifyURL);
            resolve({ status: METHOD_STATUSES.SUCCESS });
        });
    }
}

export default CommonSdkMethods;
