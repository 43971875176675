import {
    globalLoggerToken,
    growaveConfigurationToken,
    gwStorefrontAppInfoToken,
} from "@/tokens";
import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";
import { container, inject, instanceCachingFactory, singleton } from "tsyringe";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import { LoggerInterface } from "@interfaces/LoggerInterface";
import { ModuleInterface } from "@interfaces/ModuleInterface";

import ApplyDiscountAvailabilityProvider from "./ApplyDiscountAvailabilityProvider/ApplyDiscountAvailabilityProvider";
import { BaseUrlProvider } from "./BaseUrlProvider/BaseUrlProvider";
import CartAvailabilityProvider from "./CartAvailabilityProvider/CartAvailabilityProvider";
import { DesignModeProviderInterface } from "./DesignModeProvider/DesignModeProviderInterface";
import { FromShopifyObjectDesignModeProvider } from "./DesignModeProvider/FromShopifyObjectDesignModeProvider";
import DesignModeQueryParamsProvider from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProvider";
import DesignModeQueryParamsProviderInterface from "./DesignModeQueryParamsProvider/DesignModeQueryParamsProviderInterface";
import { referrerProviderToken } from "./di_tokens";
import { GraphQLApiClient } from "./GraphQLApiClient/GraphQLApiClient";
import LocationController from "./LocationController/LocationController";
import LocationControllerInterface from "./LocationController/LocationControllerInterface";
import { PathPrefixProvider } from "./PathPrefixProvider/PathPrefixProvider";
import ReferrerProvider, {
    ReferrerProviderInterface,
} from "./ReferrerProvider";
import { ShopifyUrlUtils } from "./ShopifyUrlUtils/ShopifyUrlUtils";
import { StorefrontApiBaseUrlProvider } from "./StorefrontApiBaseUrlProvider/StorefrontApiBaseUrlProvider";
import { URLsBuilder } from "./URLsBuilder/URLsBuilder";

interface CommonModuleExports {
    locationController: LocationControllerInterface;
    referrerProvider: ReferrerProviderInterface;
    applyDiscountAvailabilityProvider: ApplyDiscountAvailabilityProvider;
    cartAvailabilityProvider: CartAvailabilityProvider;
    designModeProvider: DesignModeProviderInterface;
    designModeQueryParamsProvider: DesignModeQueryParamsProviderInterface;
    baseUrlProvider: BaseUrlProvider;
    graphqlApiClient: GraphQLApiClient | null;
    urlsBuilder: URLsBuilder;
    pathPrefixProvider: PathPrefixProvider;
}

@singleton()
export class CommonModule implements ModuleInterface<CommonModuleExports> {
    exports: CommonModuleExports;

    public constructor(
        @inject(globalLoggerToken)
        private readonly globalLogger: LoggerInterface,
        @inject(gwStorefrontAppInfoToken)
        private readonly gwStorefrontAppInfo: GwStorefrontAppInfo,
        @inject(growaveConfigurationToken)
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {
        const commonModuleLogger = this.globalLogger.newLogger("CommonModule");

        const locationController = new LocationController(window.location);

        const shopifyUrlUtils = new ShopifyUrlUtils(
            window.Shopify?.routes?.root || "/"
        );

        const pathPrefixProvider = new PathPrefixProvider(
            window.Shopify?.routes?.root || "/"
        );

        container.register(referrerProviderToken, {
            useFactory: instanceCachingFactory(() => {
                return new ReferrerProvider();
            }),
        });

        const designModeProvider = new FromShopifyObjectDesignModeProvider();

        const designModeQueryParamsProvider = new DesignModeQueryParamsProvider(
            designModeProvider,
            locationController
        );

        const baseUrlProvider = new BaseUrlProvider(
            this.gwStorefrontAppInfo,
            this.growaveConfiguration
        );

        const storefrontApiBaseUrlProvider = new StorefrontApiBaseUrlProvider(
            this.gwStorefrontAppInfo,
            this.growaveConfiguration
        );

        let graphqlApiClient: GraphQLApiClient | null = null;
        if (growaveConfiguration.storefrontApiAccessToken) {
            graphqlApiClient = new GraphQLApiClient(
                storefrontApiBaseUrlProvider.appendToBaseUrl(
                    "/api/2024-07/graphql.json"
                ),
                growaveConfiguration.storefrontApiAccessToken
            );
        }

        const urlsBuilder = new URLsBuilder(
            shopifyUrlUtils,
            locationController
        );

        this.exports = {
            locationController: locationController,
            referrerProvider: container.resolve(referrerProviderToken),
            applyDiscountAvailabilityProvider:
                new ApplyDiscountAvailabilityProvider(
                    this.growaveConfiguration.isDiscountApplyAvailable
                ),
            cartAvailabilityProvider: new CartAvailabilityProvider(
                this.growaveConfiguration.isCartAvailable
            ),
            designModeProvider,
            designModeQueryParamsProvider,
            baseUrlProvider,
            graphqlApiClient,
            urlsBuilder,
            pathPrefixProvider,
        };
    }

    public registerProviders() {
        return {};
    }
}
