export enum AUTH_MODULE_SDK_METHODS {
    SET_ACCESS_TOKEN_STORAGE = "setAccessTokenStorage",
    SET_REFRESH_TOKEN_STORAGE = "setRefreshTokenStorage",
    GET_CURRENT_TOKEN_INFO = "getCurrentToken",
    AUTHENTICATE_CUSTOMER = "authenticateCustomer",
    LOGOUT_CUSTOMER = "logoutCustomer",
}

export enum ROLE {
    Guest = "guest",
    Customer = "customer",
}
