import ApiClientRequest from "../ApiClientRequest";
import { ApiClientResponse } from "..";

class BaseApiClientException {
    constructor(
        public readonly message: string,
        public readonly code: number,
        public readonly request: ApiClientRequest,
        public readonly response: ApiClientResponse<unknown>
    ) {}
}

export default BaseApiClientException;
