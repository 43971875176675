import { Infer, object, string } from "superstruct";

export const TikTokApplicationConfigSchema = object({
    placeholderSelector: string(),
});

export const TikTokApplicationConfigToken = Symbol.for(
    "TikTokApplicationConfig"
);

export type TikTokApplicationConfigInterface = Infer<
    typeof TikTokApplicationConfigSchema
>;
