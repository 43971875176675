import { ApiClientResponse, JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable } from "rxjs";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import AnalyticsEventsCollectorApiServiceInteraface from "./AnalyticsEventsSender/AnalyticsEventsCollectorApiServiceInteraface";

class MetricsCollectorApiService
    implements AnalyticsEventsCollectorApiServiceInteraface
{
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}
    sendEvents(events: any[]): Observable<ApiClientResponse<unknown>> {
        this.logger.debug("MetricsCollectorService.sendEvents", {
            events,
        });
        return this.apiClient.post({
            url: "/events/storefront",
            body: JSON.stringify({ events }),
            responseType: "text",
            contentType: JsonMimeType,
        });
    }
}

export default MetricsCollectorApiService;
