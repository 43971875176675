import { globalLoggerToken } from "@/tokens";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";
import { ModuleInterface } from "@interfaces/ModuleInterface";

import ModulesStatusesProvider from "./ModulesStatusesProvider/ModulesStatusesProvider";

interface BaseModuleExports {
    modulesStatusesProvider: ModulesStatusesProvider;
}

@singleton()
export class BaseModule implements ModuleInterface<BaseModuleExports> {
    exports: BaseModuleExports;

    public constructor(
        @inject(globalLoggerToken)
        private readonly globalLogger: LoggerInterface
    ) {
        const logger = this.globalLogger.newLogger("BaseModule");

        const modulesStatusesProvider = new ModulesStatusesProvider(logger);

        this.exports = {
            modulesStatusesProvider,
        };
    }

    public registerProviders() {
        return {};
    }
}
