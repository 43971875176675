import Sdk from "@modules/sdk/Sdk";
import { SdkRegisteredModuleInterface } from "@modules/sdk/SdkRegisteredModuleInterface";
import { singleton } from "tsyringe";

import AuthenticationSdkMethods from "./AuthenticationSdkMethods";

@singleton()
class AuthenticationSdk implements SdkRegisteredModuleInterface {
    public readonly methods: AuthenticationSdkMethods;

    constructor(
        private readonly authenticationsSdkMethods: AuthenticationSdkMethods
    ) {
        this.methods = this.authenticationsSdkMethods;
    }

    public register(sdk: Sdk & { authentication?: AuthenticationSdk }) {
        sdk.authentication = this;
    }
}

export default AuthenticationSdk;
