import {
    EventDispatchedEventInterface,
    EventSubscribedEventInterface,
    EventUnsubscribedEventInterface,
    MethodCalledEventInterface,
    MethodSubscribedEventInterface,
    MethodUnsubscribedEventInterface,
    SdkModuleRegisteredEventInterface,
    SdkStartedEventInterface,
} from "./types";

class SdkAnalyticsEventsFactory {
    constructor(
        private readonly userAgent: string,
        private readonly location: string,
        private readonly app: string
    ) {}

    private getHeaders() {
        return {
            app: this.app,
            userAgent: this.userAgent,
            location: this.location,
            dateTime: new Date(),
        };
    }

    public createSdkStarted(): SdkStartedEventInterface {
        return {
            type: "SDK.Started",
            headers: this.getHeaders(),
            payload: {},
        };
    }

    public createSdkModuleRegistered(
        moduleName: string
    ): SdkModuleRegisteredEventInterface {
        return {
            type: "SDK.ModuleRegistered",
            headers: this.getHeaders(),
            payload: {
                moduleName,
            },
        };
    }

    public createEventSubscribed(
        eventType: string
    ): EventSubscribedEventInterface {
        return {
            type: "SDK.EventSubscribed",
            headers: this.getHeaders(),
            payload: {
                eventType,
            },
        };
    }

    public createEventUnsubscribed(
        eventType: string
    ): EventUnsubscribedEventInterface {
        return {
            type: "SDK.EventUnsubscribed",
            headers: this.getHeaders(),
            payload: {
                eventType,
            },
        };
    }

    public createEventDispatched(
        eventType: string
    ): EventDispatchedEventInterface {
        return {
            type: "SDK.EventDispatched",
            headers: this.getHeaders(),
            payload: {
                eventType,
            },
        };
    }

    public createMethodSubscribed(
        methodName: string
    ): MethodSubscribedEventInterface {
        return {
            type: "SDK.MethodSubscribed",
            headers: this.getHeaders(),
            payload: {
                methodName,
            },
        };
    }

    public createMethodUnsubscribed(
        methodName: string
    ): MethodUnsubscribedEventInterface {
        return {
            type: "SDK.MethodUnsubscribed",
            headers: this.getHeaders(),
            payload: {
                methodName,
            },
        };
    }

    public createMethodCalled(methodName: string): MethodCalledEventInterface {
        return {
            type: "SDK.MethodCalled",
            headers: this.getHeaders(),
            payload: {
                methodName,
            },
        };
    }
}

export default SdkAnalyticsEventsFactory;
