import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { BaseModule } from "@modules/base_module/BaseModule";
import { container, singleton } from "tsyringe";

import { ModuleInterface } from "@interfaces/ModuleInterface";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import CommonSdk from "./common_sdk/CommonSdk";
import { SDK_MODULES, SDK_READY } from "./constants";
import SdkMethodsCollector from "./methods/SdkMethodsCollector";
import Sdk from "./Sdk";
import { SdkInitOptions } from "./types";

@singleton()
export class SdkModule
    implements
        ModuleInterface<{
            sdk: Sdk;
            sdkMethodsCollector: SdkMethodsCollector;
        }>
{
    exports: {
        sdk: Sdk;
        sdkMethodsCollector: SdkMethodsCollector;
    };

    public constructor(initApp?: (options: SdkInitOptions) => void) {
        const sdkAnalyticsEventsFactory = new SdkAnalyticsEventsFactory(
            navigator.userAgent,
            location.href,
            __APP_NAME__
        );

        const baseModule = container.resolve(BaseModule);

        const sdk = new Sdk(
            container.resolve(AnalyticsEventsCollectorModule),
            baseModule,
            sdkAnalyticsEventsFactory,
            initApp
        );

        const sdkMethodsCollector = new SdkMethodsCollector(
            container.resolve(
                AnalyticsEventsCollectorModule
            ).exports.eventsCollector,
            sdkAnalyticsEventsFactory
        );

        sdk.registerModule(
            SDK_MODULES.COMMON,
            new CommonSdk(
                baseModule.exports.modulesStatusesProvider,
                container.resolve(ProductIdFromSdkProvider),
                container.resolve(ProductVariantIdFromSdkProvider)
            )
        );

        if (!window.gw) {
            window.gw = {};
        }

        if (!window.gw.sdk) {
            window.gw.sdk = sdk;
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsCollector.pushEvent(
                    sdkAnalyticsEventsFactory.createSdkStarted()
                );
        }

        // This timeout is needed to ensure that SDK object correctly registered in DiContainer, before SDK_READY event is dispatched
        // Without this timeout, handlers for SDK_READY will be executed synchronously, before SDK object is registered
        setTimeout(() => {
            document.dispatchEvent(new Event(SDK_READY));
        }, 0);

        this.exports = {
            sdk,
            sdkMethodsCollector,
        };
    }
    registerProviders() {
        return {};
    }
}
