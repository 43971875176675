import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable, map } from "rxjs";
import { create } from "superstruct";
import { inject, singleton } from "tsyringe";

import ProductEntity from "../entities/ProductEntity";
import { commonAuthApiClientToken } from "../tokens";
import { ProductApiServiceInterface } from "./ProductApiServiceInterface";
import { GrowaveProductResponseSchema } from "./responses/GrowaveProductResponseSchema";

@singleton()
export class GrowaveProductApiService implements ProductApiServiceInterface {
    constructor(
        @inject(commonAuthApiClientToken)
        private readonly apiClient: ApiClientInterface
    ) {}

    public getProduct(id: number): Observable<ProductEntity> {
        return this.apiClient
            .get({
                url: "/getProduct",
                queryParams: {
                    productId: id,
                },
            })
            .pipe(
                map((response): ProductEntity => {
                    const responseData = create(
                        response.body,
                        GrowaveProductResponseSchema
                    );
                    return {
                        id: responseData.product.id,
                        title: responseData.product.title,
                        description: responseData.product.description,
                        featuredImage: {
                            id: responseData.product.images.find(
                                (image) =>
                                    image.url === responseData.product.imageUrl
                            )?.id as number,
                            url: responseData.product.imageUrl,
                            altText: "",
                        },
                        images: responseData.product.images.map((image) => ({
                            id: image.id,
                            url: image.url,
                            altText: "",
                        })),
                        handle: responseData.product.slug,
                        variants: responseData.product.variants.map(
                            (variant) => {
                                return {
                                    id: variant.id,
                                    title: variant.title,
                                    image: variant.imageId
                                        ? {
                                              id: variant.imageId,
                                              url: responseData.product.images.find(
                                                  (image) =>
                                                      image.id ===
                                                      (variant.imageId as number)
                                              )?.url as string,
                                              altText: "",
                                          }
                                        : null,
                                    price: {
                                        amount: variant.price,
                                        currency: null,
                                    },
                                    compareAtPrice: variant.comparePrice
                                        ? {
                                              amount: variant.comparePrice,
                                              currency: null,
                                          }
                                        : null,
                                    quantityAvailable:
                                        variant.inventoryQuantity,
                                    currentlyNotInStock:
                                        variant.inventoryPolicy ===
                                            "continue" &&
                                        variant.inventoryQuantity < 1
                                            ? true
                                            : false,
                                    availableForSale: !(
                                        variant.inventoryManagement ===
                                            "shopify" &&
                                        variant.inventoryPolicy === "deny" &&
                                        variant.inventoryQuantity < 1
                                    ),
                                    options: [],
                                };
                            }
                        ),
                        options: responseData.product.options.map(
                            (option, idx) => ({
                                name: option.name,
                                values: responseData.product.variants.reduce(
                                    (acc, variant) => {
                                        const variantOptionValue =
                                            variant[
                                                `option${idx + 1}` as "option1"
                                            ];
                                        if (
                                            variantOptionValue &&
                                            !acc.includes(variantOptionValue)
                                        ) {
                                            return [...acc, variantOptionValue];
                                        }
                                        return acc;
                                    },
                                    [] as string[]
                                ),
                            })
                        ),
                    };
                })
            );
    }
}
