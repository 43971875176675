import ApiClientRequest from "../ApiClientRequest";
import BaseApiClientException from "./BaseApiClientException";
import { ApiClientResponse } from "..";

class GatewayTimeoutException extends BaseApiClientException {
    static STATUS_CODE = 504;
    constructor(
        request: ApiClientRequest,
        response: ApiClientResponse<unknown>
    ) {
        super(
            "Gateway timeout",
            GatewayTimeoutException.STATUS_CODE,
            request,
            response
        );
    }
}

export default GatewayTimeoutException;
