// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,
:host {
    --gw-button-accent-color: #072448;
    --gw-button-secondary-color: #fff;
    --gw-button-text-bg: var(--gw-lightgrey-bg);
    --gw-button-transition-duration: 0.15s;
    --gw-button-border-radius-sm: 8px;
    --gw-button-border-radius-md: 4px;
    --gw-button-border-radius-lg: 4px;
    --gw-button-text-sm: 10px;
    --gw-button-text-md: 12px;
    --gw-button-text-lg: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/button.css"],"names":[],"mappings":"AAAA;;IAEI,iCAAiC;IACjC,iCAAiC;IACjC,2CAA2C;IAC3C,sCAAsC;IACtC,iCAAiC;IACjC,iCAAiC;IACjC,iCAAiC;IACjC,yBAAyB;IACzB,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":[":root,\n:host {\n    --gw-button-accent-color: #072448;\n    --gw-button-secondary-color: #fff;\n    --gw-button-text-bg: var(--gw-lightgrey-bg);\n    --gw-button-transition-duration: 0.15s;\n    --gw-button-border-radius-sm: 8px;\n    --gw-button-border-radius-md: 4px;\n    --gw-button-border-radius-lg: 4px;\n    --gw-button-text-sm: 10px;\n    --gw-button-text-md: 12px;\n    --gw-button-text-lg: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
