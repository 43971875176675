import {
    array,
    boolean,
    nullable,
    number,
    object,
    optional,
    string,
} from "superstruct";

export const CustomerInfoSchema = object({
    email: nullable(string()),
    id: nullable(number()),
    isB2b: optional(boolean()),
    tags: array(string()),
});
