import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";

export class BaseUrlProvider {
    constructor(
        private readonly gwStorefrontAppInfo: GwStorefrontAppInfo,
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {}
    getBaseUrl() {
        if (this.growaveConfiguration.initedFromSdk) {
            return this.gwStorefrontAppInfo.appBaseUrl;
        } else {
            return this.gwStorefrontAppInfo.appProxyPrefix;
        }
    }
    appendToBaseUrl(path: string) {
        return this.getBaseUrl() + path;
    }
}
