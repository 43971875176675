import { BehaviorSubject, Observable } from "rxjs";

import { ProductVariantIdProviderInterface } from "./ProductVariantIdProviderInterface";

export class ProductVariantIdFromSdkProvider
    implements ProductVariantIdProviderInterface
{
    private _variantId = new BehaviorSubject<number | null>(null);

    setVariantId(variantId: number | null): void {
        this._variantId.next(variantId);
    }

    selectVariantId(): Observable<number | null> {
        return this._variantId.asObservable();
    }
}
