import {
    CURRENT_APP_QUERY_PARAMETER,
    GROWAVE_TOKEN_STRATEGY,
    TOKEN_STRATEGY_HEADER_KEY,
    TOKEN_STRATEGY_QUERY_PARAMETER,
} from "@/constants/current_app";
import { ModuleInterface } from "@/interfaces/ModuleInterface";
import {
    globalLoggerToken,
    growaveConfigurationToken,
    gwStorefrontAppInfoToken,
} from "@/tokens";
import ApiClient from "@lib/ApiClient";
import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";
import { CommonModule } from "@modules/common_module/CommonModule";
import { SDK_MODULES } from "@modules/sdk/constants";
import { SdkModule } from "@modules/sdk/SdkModule";
import { TokenManagerModule } from "@modules/token_manager/TokenManagerModule";
import { container, inject, instanceCachingFactory, singleton } from "tsyringe";

import AuthenticationSdk from "./authentication_sdk/AuthenticationSdk";
import { AuthenticationInfoProvider } from "./AuthenticationInfoProvider/AuthenticationInfoProvider";
import { ProxyAuthenticator } from "./authenticators/ProxyAuthenticator";
import { CurrentTokenChecker } from "./CurrentTokenChecker";
import {
    authServiceApiClientToken,
    authenticationInfoProviderToken,
} from "./di_tokens";

@singleton()
export class AuthenticationModule
    implements
        ModuleInterface<{
            authenticationInfoProvider: AuthenticationInfoProvider;
        }>
{
    exports: { authenticationInfoProvider: AuthenticationInfoProvider };
    public constructor(
        sdkModule: SdkModule,
        tokenManagerModule: TokenManagerModule,
        @inject(CommonModule)
        private readonly commonModule: CommonModule,
        @inject(growaveConfigurationToken)
        private readonly growaveConfiguration: GrowaveConfigurationEntity
    ) {
        container.registerInstance(
            authServiceApiClientToken,
            new ApiClient(
                this.commonModule.exports.baseUrlProvider.appendToBaseUrl(
                    "/storefront-api/storefront-authentication-service/v2"
                ),
                {
                    [TOKEN_STRATEGY_HEADER_KEY]: GROWAVE_TOKEN_STRATEGY,
                },
                {
                    [CURRENT_APP_QUERY_PARAMETER]: container.resolve(
                        gwStorefrontAppInfoToken
                    ).currentApp,
                    [TOKEN_STRATEGY_QUERY_PARAMETER]: GROWAVE_TOKEN_STRATEGY,
                    ...this.commonModule.exports.designModeQueryParamsProvider
                        .queryParams,
                },
                container.resolve(globalLoggerToken)
            )
        );

        container.register(authenticationInfoProviderToken, {
            useFactory: instanceCachingFactory((di) => {
                return new AuthenticationInfoProvider(
                    tokenManagerModule.exports.tokenManager
                );
            }),
        });

        const authenticationSdk = container.resolve(AuthenticationSdk);
        sdkModule.exports.sdk.registerModule(
            SDK_MODULES.AUTHENTICATION,
            authenticationSdk
        );

        container.resolve(CurrentTokenChecker).check();
        void container.resolve(ProxyAuthenticator).authenticate();
        this.exports = {
            authenticationInfoProvider: container.resolve(
                authenticationInfoProviderToken
            ),
        };
    }
    registerProviders() {
        return {};
    }
}
