import { enums, nullable, number, string, type } from "superstruct";

import { ROLE } from "../constants";

const GrowaveJwtTokenSchema = type({
    iat: number(),
    exp: number(),
    data: type({
        sessionToken: string(),
        customerId: nullable(number()),
        shopifyCustomerId: nullable(number()),
        role: enums(Object.values(ROLE)),
        shopId: number(),
    }),
});

export default GrowaveJwtTokenSchema;
