import { App } from "@/constants";
import { SDK_MODULES } from "@modules/sdk/constants";
import { BehaviorSubject, Observable, map } from "rxjs";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { MODULE_STATUS } from "./types";

type ModulesStatuses = Record<App | SDK_MODULES, MODULE_STATUS>;

@singleton()
class ModulesStatusesProvider {
    private modulesStatuses: BehaviorSubject<ModulesStatuses>;

    constructor(private readonly logger: LoggerInterface) {
        this.logger.debug("ModulesStatusesProvider is initialized");

        const modulesStatuses: ModulesStatuses = {} as ModulesStatuses;

        Object.values(App).forEach((module) => {
            modulesStatuses[module] = MODULE_STATUS.NOT_INITIALIZED;
        });

        Object.values(SDK_MODULES).forEach((module) => {
            modulesStatuses[module] = MODULE_STATUS.NOT_INITIALIZED;
        });

        this.modulesStatuses = new BehaviorSubject<ModulesStatuses>(
            modulesStatuses
        );
    }

    selectModuleStatus(module: App | SDK_MODULES): Observable<MODULE_STATUS> {
        return this.modulesStatuses.pipe(
            map((appsStatuses) => appsStatuses[module])
        );
    }

    updateModuleStatus(module: App | SDK_MODULES, status: MODULE_STATUS) {
        const appsStatuses = this.modulesStatuses.value;
        appsStatuses[module] = status;
        this.modulesStatuses.next(appsStatuses);
    }
}

export default ModulesStatusesProvider;
