import { Infer, object, string } from "superstruct";

export const InstagramApplicationConfigSchema = object({
    placeholderSelector: string(),
});

export const InstagramApplicationConfigToken = Symbol.for(
    "InstagramApplicationConfig"
);

export type InstagramApplicationConfigInterface = Infer<
    typeof InstagramApplicationConfigSchema
>;
