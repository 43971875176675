import { SdkEventInterface } from "./SdkEventInterface";

class SdkEvent extends Event {
    public readonly detail: SdkEventInterface;

    constructor(type: string, detail: SdkEventInterface) {
        super(type);
        this.detail = detail;
    }
}

export default SdkEvent;
