import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import { NudgesApplicationConfigInterface } from "./NudgesApplicationConfig";

export const nudgesAuthApiClientToken = Symbol(
    "nudgesAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const nudgesRewardsAuthApiClientToken = Symbol(
    "nudgesRewardsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const NudgesWidgetConfigToken = Symbol("NudgesWidgetConfigToken");

export const NudgesApplicationConfigToken = Symbol(
    "NudgesApplicationConfigToken"
) as InjectionToken<NudgesApplicationConfigInterface>;

export const nudgesApplicationLoggerToken = Symbol(
    "NudgesApplicationLoggerToken"
);
