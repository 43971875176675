// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root, :host {
    /* layout gutter */
    --gw-layout-col-gutter: 0;

    /* container gutter */
    --gw-layout-container-gutter: 0;

    --gw-layout-gap-compact: 8px;
    --gw-layout-gap-wide: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/themes/default/layout.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;;IAEzB,qBAAqB;IACrB,+BAA+B;;IAE/B,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[":root, :host {\n    /* layout gutter */\n    --gw-layout-col-gutter: 0;\n\n    /* container gutter */\n    --gw-layout-container-gutter: 0;\n\n    --gw-layout-gap-compact: 8px;\n    --gw-layout-gap-wide: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
