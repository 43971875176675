import { array, nullable, number, object } from "superstruct";

export const ProductWithQuantityRulesSchema = object({
    id: number(),
    variants: array(
        object({
            id: number(),
            quantityRule: object({
                increment: number(),
                minimum: number(),
                maximum: nullable(number()),
            }),
        })
    ),
});
