export class CallbackParams {
    constructor(public modal_name: string) {}
}

export interface ModalParams {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface Modal {
    name: string;
    default_params: ModalParams;
}

export type ModalsList = Modal[];

export interface OpenModal {
    name: string;
    params: ModalParams;
    order: number;
}

export type OpenModalsList = OpenModal[];

export type Event =
    | "afterRegister"
    | "afterOpen"
    | "beforeOpen"
    | "afterClose"
    | "beforeClose";

export type CallbackFunction = (cb_params: CallbackParams) => unknown;

export type Callbacks = {
    [key in Event]: CallbackFunction[];
};

export type Subscribers = {
    [key: string]: (() => void)[];
};
