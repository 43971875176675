class Timer {
    time: Date;
    constructor() {
        this.time = new Date();
    }
    ready(): number {
        const currentTime = new Date();
        return currentTime.getTime() - this.time.getTime();
    }
}

export default Timer;
