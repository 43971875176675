import { Observable, from } from "rxjs";

export class GraphQLApiClient {
    private graphqlApiUrl: string;
    private token: string;

    public constructor(graphqlApiUrl: string, token: string) {
        this.graphqlApiUrl = graphqlApiUrl;
        this.token = token;
    }

    public request<T>(
        query: string,
        variables?: Record<string, unknown>
    ): Observable<T> {
        return from(
            fetch(this.graphqlApiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Shopify-Storefront-Access-Token": this.token,
                },
                body: JSON.stringify({
                    query,
                    variables: variables || {},
                }),
            })
                .then(async (response) => {
                    const result = (await response.json()) as {
                        data: T;
                        errors?: { message: string }[];
                    };
                    if (result.errors) {
                        throw new Error(JSON.stringify(result.errors));
                    }
                    return result.data;
                })
                .catch((error: Error) => {
                    throw new Error(`GraphQL request failed: ${error.message}`);
                })
        );
    }
}
