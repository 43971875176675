import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    map,
    switchMap,
} from "rxjs";

import { ProductVariantIdManagerInterface } from "./ProductVariantIdManagerInterface";
import { ProductVariantIdProviderInterface } from "./ProductVariantIdProviderInterface";

export class ProductVariantsIdManager
    implements ProductVariantIdManagerInterface
{
    providers$ = new BehaviorSubject<ProductVariantIdProviderInterface[]>([]);
    variantId$ = new BehaviorSubject<number | null>(null);

    constructor() {
        this.providers$
            .pipe(
                map((providers) =>
                    providers.map((provider) => provider.selectVariantId())
                ),
                switchMap((arr) => combineLatest(arr)),
                map((values) => this._getLastValidVariantId(values)),
                distinctUntilChanged()
            )
            .subscribe(this.variantId$);
    }

    private _getLastValidVariantId(values: (number | null)[]) {
        if (!values.length) return null;

        let idx = values.length - 1;

        while (idx >= 0) {
            if (values[idx]) return values[idx];
            else idx--;
        }

        return null;
    }

    public registerProvider(provider: ProductVariantIdProviderInterface): void {
        this.providers$.next([...this.providers$.getValue(), provider]);
    }

    public unregisterProvider(
        provider: ProductVariantIdProviderInterface
    ): void {
        const providers = this.providers$.value;
        this.providers$.next(providers.filter((item) => item !== provider));
    }
}
