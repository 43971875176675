import { CURRENT_APP_QUERY_PARAMETER } from "@/constants/current_app";
import { ModuleInterface } from "@/interfaces/ModuleInterface";
import {
    globalLoggerToken,
    growaveConfigurationProviderToken,
    gwStorefrontAppInfoToken,
    localStorageToken,
} from "@/tokens";
import ApiClient from "@lib/ApiClient";
import { GrowaveConfigurationProviderInterface } from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationProviderInterface";
import { tokenManagerToken } from "@modules/authentication/di_tokens";
import { CommonModule } from "@modules/common_module/CommonModule";
import {
    DependencyContainer,
    container,
    inject,
    instanceCachingFactory,
    singleton,
} from "tsyringe";

import { RefreshTokenApiService } from "./api_services/RefreshTokenApiService";
import { refreshTokenServiceApiClientToken } from "./di_tokens";
import GrowaveTokenManager from "./TokenManager/GrowaveTokenManager";
import TokenManagerInterface from "./TokenManager/TokenManagerInterface";

@singleton()
export class TokenManagerModule
    implements ModuleInterface<{ tokenManager: TokenManagerInterface }>
{
    exports: { tokenManager: TokenManagerInterface };
    public constructor(
        @inject(CommonModule)
        private readonly commonModule: CommonModule,
        @inject(growaveConfigurationProviderToken)
        private readonly growaveConfigurationProvider: GrowaveConfigurationProviderInterface
    ) {
        container.registerInstance(
            refreshTokenServiceApiClientToken,
            new ApiClient(
                this.commonModule.exports.baseUrlProvider.appendToBaseUrl(
                    "/storefront-api/storefront-authentication-service/v2"
                ),
                undefined,
                {
                    [CURRENT_APP_QUERY_PARAMETER]: container.resolve(
                        gwStorefrontAppInfoToken
                    ).currentApp,
                    ...this.commonModule.exports.designModeQueryParamsProvider
                        .queryParams,
                },
                container.resolve(globalLoggerToken)
            )
        );

        container.register(tokenManagerToken, {
            useFactory: instanceCachingFactory((di) => {
                return new GrowaveTokenManager(
                    container.resolve(RefreshTokenApiService),
                    container.resolve(localStorageToken),
                    this.commonModule.exports.designModeProvider.isDesignMode()
                        ? "GW_TOKEN__DESIGN_MODE"
                        : "GW_TOKEN",
                    0,
                    growaveConfigurationProvider.getConfiguration().myshopifyDomain,
                    container.resolve(globalLoggerToken)
                );
            }),
        });
        this.exports = {
            tokenManager: container.resolve(tokenManagerToken),
        };
    }
    registerProviders(di: DependencyContainer) {
        return {};
    }
}
