import ApiClientRequest from "../ApiClientRequest";

class UnknownFetchException extends Error {
    constructor(
        public readonly request: ApiClientRequest,
        public readonly originalError: unknown
    ) {
        super("Unknown fetch error");
    }
}

export default UnknownFetchException;
