import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";
import { create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import GrowaveConfigurationBodySchema from "./GrowaveConfigurationBodySchema";
import GrowaveConfigurationEntity from "./GrowaveConfigurationEntity";
import { GrowaveConfigurationProviderInterface } from "./GrowaveConfigurationProviderInterface";

class GrowaveConfigurationFromJsonProvider
    implements GrowaveConfigurationProviderInterface
{
    constructor(private readonly logger: LoggerInterface) {}

    getConfiguration(): GrowaveConfigurationEntity {
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );
        const growaveConfiguration = create(
            {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
                ...(additionalInfoData as any).FEATURES,
                ...{
                    myshopifyDomain: window.Shopify?.shop || "",
                    isDiscountApplyAvailable: true,
                    isCartAvailable: true,
                },
            },
            GrowaveConfigurationBodySchema
        );
        this.logger.debug(
            "GrowaveConfigurationFromJsonProvider.getConfiguration",
            {
                growaveConfiguration: growaveConfiguration,
            }
        );
        return growaveConfiguration;
    }
}

export default GrowaveConfigurationFromJsonProvider;
